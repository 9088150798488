<template>
	<div>
		<el-form ref="form" :model="formData" :rules="rules" label-width="80px">
			<el-form-item label="物流单号" prop="dvyFlowId"><el-input v-model="formData.dvyFlowId"></el-input></el-form-item>
			<el-form-item label="快递公司" prop="dvyId">
				<el-select v-model="formData.dvyId" placeholder="请选择快递公司">
					<el-option v-for="(item,index) in option" :label="item.dvyName" :value="item.dvyId" :key="index"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<div class="btnArea">
			<el-button size="small" @click="handleChangeClose">关 闭</el-button>
			<el-button size="small" type="custom" @click="handleChangeConfirm()">确 定</el-button>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			selectdata:{
				type:Object,
				default:()=>{}
			}
		},
		data(){
			return{
				rules: {
					dvyFlowId: [
						{ required: true, message: '请输入物流单号', trigger: 'blur' },
					],
					dvyId: [
						{ required: true, message: '请选择快递公司', trigger: ['change','blur'] },
					],
				},
				formData:{
					dvyFlowId:"",
					dvyId:"",
					orderNumber:""
				},
				option:[]
			}
		},
		created() {
			this.formData.dvyFlowId = this.selectdata.dvyFlowId
			this.formData.dvyId = this.selectdata.dvyId
			this.formData.orderNumber = this.selectdata.orderNumber
			this.getFlowCompany()
		},
		methods:{
			async getFlowCompany(){
				const res = await this.$axios("post","/sys/delivery/list",{},true)
				this.option = res
			},
			handleChangeClose(){
				this.$emit('schange', false);
			},
			async handleChangeConfirm(){
				this.$refs['form'].validate(async valid => {
					if (valid) {
						const res = await this.$axios("post","/sys/order/delivery",this.formData,true)
						if(res.code && res.code===30001){
							return this.$message.error(res.msg);
						} 
						this.$message.success('已发货！');
						this.$emit('fchange', false);
					} else {
						this.$message.error('请完善表单信息后重试！');
					}
				});
			}
		}
	}
</script>

<style></style>
